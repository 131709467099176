<template>
  <div id="works-page">
    <div class="works-page_title">
      <div class="works-page_text">WORKS</div>
    </div>
    <div class="project-container">
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>Imagined and designed by myself, I thought this portfolio
            into an original visit presenting my skills and services. The
            whole thing is accompanied by animations to make the
            visitors' journey even more pleasant and fluid.</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/vue.png" alt="" />
              <img class="icons-tools" src="../assets/img/gsap.png" alt="" />
              <a href="https://github.com/Mikael-W/Folio-V1_MW"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/folio_MW.png" alt="" />
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img
              class="project-img"
              src="../assets/img/groupomania.png"
              alt=""
            />
          </div>
          <div class="project-presentation_description">
            <p>Full social network project inspired by facebook
            created from the database to the customer
            front experience</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/vue.png" alt="" />
              <img class="icons-tools" src="../assets/img/vuex.png" alt="" />
              <img class="icons-tools" src="../assets/img/node.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/sequelize.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/mysql.png" alt="" />
              <a href="https://github.com/Mikael-W/Groupomania"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>Great collaboration with new futur skincare brand
            
              <a href="www.laerte-skincare.ch">LAERTE</a> make marvellous
              organic skincare products.
            
            I worked on animations to give a smooth effect on it</p>
            <p>
              website coming soon !
            </p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/gsap.png" alt="" />
            </div>
          </div>
          <a href="www.laerte-skincare.ch" class="project-presentation_picture">
            <img
              class="project-img"
              src="../assets/img/laerte.png"
              alt="laerte-skincare.ch"
            />
          </a>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/sopekocko.png" alt="" />
          </div>
          <div class="project-presentation_description">
            <p>On the other side of the machine, creating and
            securing a database and server for a comparative
            hot sauce application</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/node.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/mongoose.png"
                alt=""
              />
              <img class="icons-tools" src="../assets/img/mongodb.png" alt="" />
              <a href="https://github.com/Mikael-W/Sopekocko"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>Development of the customer experience part
            (front-end) of an e-commerce website with
            connection to the api (backend) and unit testing</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/javascript.png"
                alt=""
              />
              <img
                class="icons-tools"
                src="../assets/img/bootstrap.png"
                alt=""
              />
              <a href="https://github.com/Mikael-W/Orinoco"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/orinoco.png" alt="" />
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img
              class="project-img"
              src="../assets/img/lachouetteagence.png"
              alt=""
            />
          </div>
          <div class="project-presentation_description">
            <p>Seo and accessibility optimisation of a website, keywords
            research, improvement of loading speed. Compliance and
            respect of wcag standards.</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img
                class="icons-tools"
                src="../assets/img/bootstrap.png"
                alt=""
              />
              <a href="https://github.com/Mikael-W/LACHOUETTEAGENCE-SEO"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-right">
          <div class="project-presentation_description">
            <p>Integration of a mobile application for ordering meals
            online with some call to action animations</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <img class="icons-tools" src="../assets/img/sass.png" alt="" />
              <a href="hhttps://github.com/Mikael-W/WAWRZICZNYMIKAEL_3_03122020"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
          <a href="https://ohmyfood-bym.com/" class="project-presentation_picture">
            <img class="project-img" src="../assets/img/ohmyfood.png" alt="" />
          </a>
        </div>
      </div>
      <div id="project-presentation">
        <div class="project-presentation-left">
          <div class="project-presentation_picture">
            <img class="project-img" src="../assets/img/reservia.png" alt="" />
          </div>
          <div class="project-presentation_description">
            <p>Integration of an online booking site
            for hotels and private renting rooms</p>
            <div class="icons-tools_box">
              <img class="icons-tools" src="../assets/img/html-5.png" alt="" />
              <img class="icons-tools" src="../assets/img/css-3.png" alt="" />
              <a href="https://github.com/Mikael-W/WAWRZICZNYMIKAEL_02_05112020"
                ><img class="icons-tools" src="../assets/img/git.png" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: "works",
};
</script>

<style scoped>
#works-page {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.project-container {
  width: 96vw;
  height: 100vh;
  overflow: scroll;
  margin-left: 4vw;
}
.works-page_title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 4vw;
  height: 100vh;
  background: #106db0;
  border-right: 3px solid white;
  border-left: 3px solid white;
  font-family: INKFERNO, sans-serif;
}
.works-page_text {
  display: flex;
  transform: rotate(-90deg);
  white-space: nowrap;
  font-family: INKFERNO, sans-serif;
  display: flex;
  transform: rotate(-90deg);
  font-size: 3vw;
  color: white;  
  margin-left:0.5vw;
}
.project-presentation-left,
.project-presentation-right {
  display: flex;
  width: 100%;
  height: 50vh;
}
.project-presentation_picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50vh;
  background: #106db0;
}
.project-img {
  width: 80%;
  height: 75%;
  object-fit: fill;
}
.project-presentation_description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Podkova", serif;
  color: #106db0;
  width: 50%;
  height: 50vh;
  background: white;
}
.icons-tools_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.icons-tools {
  width: 2vw;
  margin: 0 1rem;
}
a{
  text-decoration: none;
}
p{
    font-size: 2vw;
    padding: 0 1.5vw;
    text-align: center;
}
@media screen and (max-width: 768px) and (orientation: portrait){
  .works-page_title {
    top: 0;
    bottom: 95vh;
    width: 100vw;
    height: 5vh;
    border: none;
  }
  .works-page_text {
    transform: none;
    font-size: 3vh;
  }
  .project-presentation-left{
      flex-direction: column;
      width: 90vw;
      height: 95vh;
      margin:0
  } 
  .project-presentation-right{
      flex-direction: column-reverse;
      width: 90vw;
      height: 95vh;
      margin:0
  }
  .project-presentation_picture{
      width: 91vw;
      height: 50vh;
  }
  .project-presentation_description {
      position: relative;
      width: 89vw;
      height: 50vh;
  }
  #project-presentation{
    height: 100vh;
  }
  .project-container{
      display: flex;
      overflow: scroll;
      width: auto;
      height: 100vh;
      margin:0;
  }
  .project-img{
    width: 80vw;
  }
  p{
    font-size: 5vw;
  }
  .icons-tools{
    width: 3.5vw;
  }
@media screen and (max-width: 425px) {
  .project-img{
    width: 80vw;
    height:25vh;
  }
  .project-presentation_picture{
      height: 45vh;
  }
  p{
    font-size: 5vw;
    padding:0.5rem;
    text-align: center;
  }
  .icons-tools{
    width: 3vw;
  }
  .icons-tools {
  margin: 0 0.5rem;
}
}
}
</style>